import { Link } from "@jog/react-components";
import { asLink, isFilled } from "@prismicio/helpers";
import { PrismicText } from "@prismicio/react";
export const Sidebar = ({ title, items, }) => (<>
        {isFilled.title(title) && (<div className="font-primary text-base">
                <PrismicText field={title}/>
            </div>)}
        <nav className="flex flex-col uppercase">
            {items === null || items === void 0 ? void 0 : items.map((link, index) => (<Link className="mb-1 text-sm text-black last:mb-0" key={index} to={asLink(link.link_url)}>
                    <PrismicText field={link.link_text}/>
                </Link>))}
        </nav>
    </>);
