import { Container, ErrorBoundary, Head } from "@/components";
import { Sidebar } from "@/components/Category/Sidebar";
import { SliceRender } from "@/components/Category/Slice";
import { useTextConversionMethod } from "@/lib/prismicio/useTextConversion";
import { asText, isFilled } from "@prismicio/helpers";
import { lowerCase, upperFirst } from "lodash-es";
export const Category = ({ title, prismic, paths, }) => {
    const textConvert = useTextConversionMethod();
    const titleFromCt = textConvert(upperFirst(lowerCase(title)));
    const titleFromPrismic = textConvert(asText(prismic.meta_tag_title));
    const nav = isFilled.contentRelationship(prismic.category_nav)
        ? prismic.category_nav
        : null;
    return (<ErrorBoundary>
            <Head title={titleFromPrismic || titleFromCt ? `${titleFromCt} | GARCIA` : "GARCIA"} robots={asText(prismic.meta_tag_robots)} description={asText(prismic.meta_tag_description)} alternate={paths}/>
            <Container>
                <div className="grid grid-cols-12 gap-x-4 lg:pt-2.5">
                    <div className="col-span-12 w-full lg:col-span-2">{nav && <Sidebar nav={nav.data}/>}</div>
                    <div className="col-span-12 w-full lg:col-span-10">
                        {prismic.body.length ? <SliceRender slices={prismic.body}/> : null}
                    </div>
                </div>
            </Container>
        </ErrorBoundary>);
};
