import { CheckMark } from "@/components/Icon/CheckMark";
import { usePdp } from "@jog/react-components";
export const CheckMarks = () => {
    const { pdp: prismicPdp } = usePdp();
    if (!prismicPdp.checkmarkBox.length)
        return <></>;
    return (<>
            <ul id="ups" className="relative border-t border-black bg-white py-6 pl-1 pr-2.5 text-sm">
                {prismicPdp.checkmarkBox
            .map((i) => i.items)
            .flat()
            .map((item, index) => (<li key={index} className="flex items-center py-1">
                            <CheckMark className="ml-2 mr-3"/>
                            <span className="text-sm text-black">{item}</span>
                        </li>))}
            </ul>
        </>);
};
