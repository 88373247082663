import { GRichText } from "@/components";
import { useStores } from "@/stores";
import { useI18n, usePdp, useSizeConflictText } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { observer } from "mobx-react";
export const SizeConflict = observer(() => {
    var _a, _b;
    const { cartStore } = useStores();
    const { $t } = useI18n();
    const { sku, fasletAvailable, detail: { ctpId }, pdp: { sizeConflictTextBgColor, sizeConflictTextColor, sizeConflictText, toolRecommend, ownSizeToolRecommend }, } = usePdp();
    const { isShowSizeAlert, toolRecommendText, ownSizeRecommendText } = useSizeConflictText(((_b = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) || [], ctpId, isFilled.richText(sizeConflictText) ? sizeConflictText : [], isFilled.richText(toolRecommend) ? toolRecommend : [], isFilled.richText(ownSizeToolRecommend) ? ownSizeToolRecommend : [], sku);
    if (!isShowSizeAlert)
        return <></>;
    return (<div className={classNames("inline-block whitespace-pre-wrap text-sm", sizeConflictTextBgColor ? "p-3" : "")} style={{ color: sizeConflictTextColor, backgroundColor: sizeConflictTextBgColor }}>
            <div className="inline-block" onClick={() => { var _a, _b; return fasletAvailable ? (_b = (_a = window === null || window === void 0 ? void 0 : window._faslet) === null || _a === void 0 ? void 0 : _a.openWidget) === null || _b === void 0 ? void 0 : _b.call(_a) : window === null || window === void 0 ? void 0 : window.open(`/${$t("size-chart")}`, "_blank"); }}>
                <GRichText emClass="cursor-pointer underline not-italic" paragraphClass="mb-0" field={fasletAvailable ? toolRecommendText : ownSizeRecommendText}/>
            </div>
        </div>);
});
