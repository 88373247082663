import { ErrorBoundary, GRichText, LogoLoader } from "@/components";
import { Facets, MobileFacets } from "@/components/Lister/Facets";
import { Products } from "@/components/Lister/Products";
import { SliceRender } from "@/components/Lister/Slice";
import { Sorting } from "@/components/Lister/Sorting";
import { useRobotsMetaTag } from "@/hooks/useRobotsMetaTag";
import { useTextConversionMethod } from "@/lib/prismicio/useTextConversion";
import { useWishlist } from "@/lib/store/wishlist";
import { Container, Pagination, SliderFacetProvider, TopImage, useI18n, useLastLister, useLister, } from "@jog/react-components";
import { getBaseCategoryFromPrettyPath, navigation } from "@jog/shared";
import { asText, isFilled } from "@prismicio/helpers";
import { lowerCase, upperFirst } from "lodash-es";
import { useEffect, useMemo } from "react";
import { Head } from "../Head/Head";
import { Filters } from "./Filters";
export const Lister = ({ prismic, path: _path, keys, title, pretty, paths, }) => {
    var _a, _b;
    const { $t } = useI18n();
    const textConvert = useTextConversionMethod();
    const wishlistLoading = useWishlist.use.actionPending();
    const categoryId = useMemo(() => keys.map((val) => `${process.env.NEXT_PUBLIC_TW_ROOT_CATEGORY_ID.slice(0, 4)}${val}`).join("-") || "", [keys]);
    const { pending, response, total, loaded, group, onChange, path } = useLister(navigation, keys, pretty, categoryId);
    const filterPageSeoContent = useMemo(() => {
        var _a;
        return (_a = [...((prismic === null || prismic === void 0 ? void 0 : prismic.filter_page_seo_content) || [])]) === null || _a === void 0 ? void 0 : _a.find((item) => item.path === path && item.filter_page_seo_content_enabled);
    }, [prismic, path]);
    const getRobotsMetaTag = useRobotsMetaTag(path);
    const meta = useMemo(() => {
        // Filter page metadata has precedence over base category metadata
        if (filterPageSeoContent) {
            return {
                title: asText(filterPageSeoContent === null || filterPageSeoContent === void 0 ? void 0 : filterPageSeoContent.filter_page_meta_tag_title),
                description: asText(filterPageSeoContent === null || filterPageSeoContent === void 0 ? void 0 : filterPageSeoContent.filter_page_meta_tag_description),
                robots: asText(filterPageSeoContent === null || filterPageSeoContent === void 0 ? void 0 : filterPageSeoContent.filter_page_meta_tag_robots) || getRobotsMetaTag,
            };
        }
        return {
            title: asText(prismic === null || prismic === void 0 ? void 0 : prismic.meta_tag_title),
            description: asText(prismic === null || prismic === void 0 ? void 0 : prismic.meta_tag_description),
            robots: asText(prismic === null || prismic === void 0 ? void 0 : prismic.meta_tag_robots) || getRobotsMetaTag,
        };
    }, [filterPageSeoContent, getRobotsMetaTag, prismic]);
    const titleFromCt = textConvert(upperFirst(lowerCase(title)));
    const carouselBanners = [...((prismic === null || prismic === void 0 ? void 0 : prismic.body) || [])].filter((i) => i.slice_type === "carousel_banners");
    const bottomDescription = useMemo(() => (filterPageSeoContent === null || filterPageSeoContent === void 0 ? void 0 : filterPageSeoContent.filter_page_bottom_description) || (prismic === null || prismic === void 0 ? void 0 : prismic.bottom_description), [filterPageSeoContent === null || filterPageSeoContent === void 0 ? void 0 : filterPageSeoContent.filter_page_bottom_description, prismic === null || prismic === void 0 ? void 0 : prismic.bottom_description]);
    const { checkScroll } = useLastLister();
    useEffect(() => {
        if (response) {
            checkScroll(response.properties, response.items);
        }
    }, [checkScroll, response]);
    return (<ErrorBoundary>
            <Head alternate={paths} pathname={(filterPageSeoContent === null || filterPageSeoContent === void 0 ? void 0 : filterPageSeoContent.path) || getBaseCategoryFromPrettyPath(path).join("/")} title={textConvert(meta.title) || (titleFromCt ? `${titleFromCt} | GARCIA` : "GARCIA")} robots={meta.robots} description={meta.description}/>
            {(wishlistLoading || pending) && <LogoLoader />}
            <Container>
                <SliderFacetProvider onChange={onChange}>
                    <div className="grid grid-cols-12 gap-x-4">
                        <div className="col-span-12 hidden w-full lg:col-span-2 lg:block">
                            <Facets expand data={response} path={_path.replace(/^\//, "")} pending={pending} onChange={onChange} loaded={loaded}/>
                        </div>
                        <div className="col-span-12 w-full lg:col-span-10">
                            {carouselBanners.length > 0 && <SliceRender slices={prismic.body}/>}
                            <TopImage prismic={prismic}/>
                            <div className="mx-0 mt-5 mb-3.75 lg:mt-0">
                                <h1 className="text-center font-primary text-sm font-medium uppercase leading-6 lg:text-left lg:text-13 lg:font-normal">
                                    {asText(prismic === null || prismic === void 0 ? void 0 : prismic.lister_title) || titleFromCt}
                                </h1>
                            </div>
                            {((_b = (_a = response === null || response === void 0 ? void 0 : response.properties) === null || _a === void 0 ? void 0 : _a.sortfields) === null || _b === void 0 ? void 0 : _b.length) > 0 && (<div className="mx-2.5 mt-4 mb-2.5 flex flex-col items-center bg-white lg:mx-1.75 lg:mt-0 lg:mb-4 lg:flex-row">
                                    <div className="flex w-full gap-x-2 lg:w-auto">
                                        <div className="flex-1 lg:hidden">
                                            <MobileFacets data={response} path={_path.replace(/^\//, "")} pending={pending} onChange={onChange}/>
                                        </div>
                                        <div className="flex-1">
                                            <Sorting onChange={onChange} sortfields={response.properties.sortfields}/>
                                        </div>
                                    </div>
                                    {total !== 0 && (<div className="w-full px-0 pt-2.5 pb-0 text-base lg:pt-0">
                                            <span>{`${total} ${$t(total > 1 ? "items" : "item")}`}</span>
                                        </div>)}
                                </div>)}
                            {response && <Filters data={response} onChange={(v) => onChange(v, true)}/>}
                            <Products group={group} data={response} loaded={loaded} banners={[...((prismic === null || prismic === void 0 ? void 0 : prismic.content_banners) || [])].slice(0, 2)} category={_path}/>
                            {response && <Pagination data={response} onChange={(v) => onChange(v, true)}/>}
                            {isFilled.richText(bottomDescription) ? (<div className="mt-7.5 px-2">
                                    <GRichText field={bottomDescription}/>
                                </div>) : null}
                        </div>
                    </div>
                </SliderFacetProvider>
            </Container>
        </ErrorBoundary>);
};
