import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, useI18n, usePdp, } from "@jog/react-components";
export const Description = () => {
    const { detail: { description }, } = usePdp();
    const { $t } = useI18n();
    if (!description)
        return <></>;
    return (<div className="border-t border-black py-6 px-1" id="description">
            <AccordionRoot type="single" collapsible defaultValue="">
                <AccordionItem value="product-description">
                    <AccordionTrigger>{$t("Description & Fit")}</AccordionTrigger>
                    <AccordionContent>
                        <div className="text-sm leading-5 text-black [&_a]:text-gray-dracula [&_a]:no-underline [&_a]:hover:cursor-pointer" dangerouslySetInnerHTML={{ __html: description }}/>
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </div>);
};
