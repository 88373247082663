import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Arrow } from "../../components";
export const Accordion = ({ textColor, bgColor, title, content, }) => {
    const [isFold, setIsFold] = useState(true);
    const variants = { open: { height: "auto", padding: "16px 0 16px 0" }, close: { height: 0, padding: 0 } };
    const style = {
        ["--text-color"]: textColor,
        ["--bg-color"]: bgColor,
    };
    return (<AnimatePresence mode="sync">
            <div className={classNames("px-4 text-[var(--text-color)]", bgColor ? "bg-[var(--bg-color)]" : "bg-gray-bg")} style={style}>
                <div onClick={() => setIsFold(!isFold)} className={classNames("flex cursor-pointer items-center justify-between border-b py-4 transition-all duration-300", { "border-b-gray-activeTitle": !isFold, "border-b-transparent": isFold })}>
                    {title}
                    <Arrow direction={isFold ? "down" : "up"} size="middle" className="shrink-0 transition-all duration-300"/>
                </div>
                <motion.div transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} animate={isFold ? "close" : "open"} variants={variants} className="overflow-hidden">
                    {content}
                </motion.div>
            </div>
        </AnimatePresence>);
};
