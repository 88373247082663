import { PromoInformationSlice, usePdp } from "@jog/react-components";
import { PdpPrice } from "./PdpPrice";
export const Header = () => {
    const { detail, pdp, productData } = usePdp();
    return (<>
            <div id="name">
                <h1 className="text-[26px] leading-9 text-black">{detail.name}</h1>
                {productData && (<div className="mt-3 flex flex-wrap items-center">
                        <PdpPrice productData={productData}/>
                    </div>)}
            </div>
            {pdp.promoInformation.map((item, index) => (<PromoInformationSlice key={index} color={item.color} richTexts={item.richTexts}/>))}
        </>);
};
