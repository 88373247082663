import { GRichText } from "@/components";
import { Link, NextImage } from "@jog/react-components";
import { asImageSrc, asLink, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
export const CtaButtons = ({ className, slice: { primary, items } }) => (<div className={classNames(className, "py-10")}>
        {isFilled.richText(primary.cta_buttons_title) ? (<div className="mt-3">
                <GRichText field={primary.cta_buttons_title}/>
            </div>) : null}

        <div className="mt-3 grid grid-cols-1 gap-2 lg:grid-cols-2">
            {items
        .filter((i) => isFilled.link(i.link))
        .map((item, index) => (<div key={index}>
                        <Link to={asLink(item.link)} className="bold flex items-center gap-2 bg-green-main p-2 uppercase text-white" style={{
            color: primary.text_color || "",
            backgroundColor: primary.button_bg_color || "",
        }}>
                            {isFilled.image(item.icon) && (<NextImage src={asImageSrc(item.icon)} alt={item.icon.alt || ""} width={40} height={40}/>)}
                            <span>{item.text}</span>
                        </Link>
                    </div>))}
        </div>
    </div>);
