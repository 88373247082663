import { Link } from "@jog/react-components";
import { asLink, asText } from "@prismicio/helpers";
import { memo } from "react";
export const Sidebar = memo(function Sidebar({ nav }) {
    return (<>
            {[...nav.body].map((slice, index) => (<div className="hidden text-black lg:block" key={index}>
                    <div className="relative z-0 my-1.75 mx-0 cursor-pointer text-xs uppercase">
                        {asText(slice.primary.sub_category)}
                    </div>
                    <ul className="mb-10">
                        {slice.items.map((item, index) => (<li className="py-1 px-0 text-13" key={index}>
                                <Link className="hover:text-red-mona" to={asLink(item.subcategory_url)}>
                                    {asText(item.product_type)}
                                </Link>
                            </li>))}
                    </ul>
                </div>))}
        </>);
});
