import { GRichText } from "@/components";
import { isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const colsMap = {
    "2": "lg:grid-cols-2",
    "3": "lg:grid-cols-3",
    "4": "lg:grid-cols-4",
    "5": "lg:grid-cols-5",
    "6": "lg:grid-cols-6",
};
const colsMobileMap = {
    "1": "grid-cols-1",
    "2": "grid-cols-2",
};
export const InfoLabels = ({ slice: { items, primary }, className }) => (<div className={classNames(className, "py-10")}>
        {isFilled.richText(primary.info_labels_title) ? (<div className="mt-3">
                <GRichText field={primary.info_labels_title}/>
            </div>) : null}
        <div className={classNames("mt-3 grid gap-2 first:mt-0", colsMap[primary.cols_per_line] || colsMap["4"], colsMobileMap[primary.cols_per_line_mobile] || colsMobileMap["1"])}>
            {items
        .filter((i) => isFilled.richText(i.label_info))
        .map((item, index) => (<div key={index} className="break-words rounded bg-gray-bg p-2 text-center lg:p-5" style={{
            color: primary.label_text_color || "",
            backgroundColor: primary.label_bg_color || "",
        }}>
                        <GRichText paragraphClass="mt-2.5" field={item.label_info}/>
                    </div>))}
        </div>
    </div>);
