import { transMoney, useI18n, usePdpPrice } from "@jog/react-components";
import classNames from "classnames";
export const PdpPrice = ({ productData }) => {
    const { currentPrice, lowestPrice, originPrice, off } = usePdpPrice(productData);
    const { $t } = useI18n();
    return (<div>
            <div className="flex items-center">
                <div className={classNames("text-26 font-medium leading-6", off > 0 ? "text-red-sale" : "text-black")}>
                    {transMoney(currentPrice)}
                </div>
                {off > 0 ? (<div className="relative ml-20 flex bg-[#D32137] py-1 px-4 text-xs text-white">
                        <span>{`${off}` + $t("Label")}</span>
                    </div>) : (<></>)}
            </div>
            {off > 0 ? (<div className="mt-4 text-sm text-grey-dark">
                    <div>{$t(`Original price: {price}`, { price: transMoney(originPrice, false) })}</div>
                    {lowestPrice === currentPrice || lowestPrice === originPrice ? null : (<div>{$t(`Lowest price last 30 days: {price}`, { price: transMoney(lowestPrice, false) })}</div>)}
                </div>) : (<></>)}
        </div>);
};
