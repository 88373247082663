import { asImageSrc, isFilled } from "@prismicio/helpers";
import { NextImage } from "../NextImage/NextImage";
export const TopImage = ({ prismic, }) => {
    if (!prismic)
        return <></>;
    const desktopImage = prismic.top_banner;
    const top_banner_display = prismic.top_banner_display || "show both";
    const mobileImage = isFilled.image(prismic.top_banner_mobile) ? prismic.top_banner_mobile : prismic.top_banner;
    return (<>
            {["show both", "only show desktop"].includes(top_banner_display) && isFilled.image(desktopImage) && (<div className="hidden w-full lg:block">
                    <NextImage src={asImageSrc(desktopImage)} alt="Banner" width={desktopImage.dimensions.width} height={desktopImage.dimensions.height} sizes="100vw" className="img-fluid w-full"/>
                </div>)}
            {["show both", "only show mobile"].includes(top_banner_display) && isFilled.image(mobileImage) && (<div className="block w-full lg:hidden">
                    <NextImage src={asImageSrc(mobileImage)} alt="Banner" width={mobileImage.dimensions.width} height={mobileImage.dimensions.height} sizes="100vw" className="img-fluid w-full"/>
                </div>)}
        </>);
};
