import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { cn, useI18n, usePdp, usePdpAdded, usePending } from "@jog/react-components";
import { gtmAddToWishlist, gtmRemoveFromWishlist } from "@jog/shared";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useCallback, useMemo } from "react";
import { Favorite } from "../Icon/Favorite";
const Wishlist = () => {
    var _a;
    const { detail, productData } = usePdp();
    const addWishlistItem = useWishlist.use.addWishlistItem();
    const removeProductFromWishlist = useWishlist.use.removeWishlistItem();
    const favoriteProducts = (_a = useWishlist.use.simpleWishlist()) === null || _a === void 0 ? void 0 : _a.lineItems;
    const lineItemId = useMemo(() => { var _a; return (_a = favoriteProducts === null || favoriteProducts === void 0 ? void 0 : favoriteProducts.find((lineItem) => lineItem.productId === detail.ctpId)) === null || _a === void 0 ? void 0 : _a.id; }, [detail, favoriteProducts]);
    const onFavorite = useCallback(async (e) => {
        var _a;
        e.preventDefault();
        const price = ((_a = ((productData === null || productData === void 0 ? void 0 : productData.firstPrice) || (productData === null || productData === void 0 ? void 0 : productData.price))) === null || _a === void 0 ? void 0 : _a.centAmount) || 0;
        const gtmPrice = +(price / 100).toFixed(2);
        const gtmItem = { id: detail.gtmId, name: detail.name, price: gtmPrice };
        if (!lineItemId) {
            gtmAddToWishlist([gtmItem]);
            await addWishlistItem(detail.ctpId);
        }
        else {
            gtmRemoveFromWishlist([gtmItem]);
            await removeProductFromWishlist(lineItemId);
        }
    }, [addWishlistItem, detail, lineItemId, productData, removeProductFromWishlist]);
    return (<button onClick={onFavorite} className="flex h-12 w-12 items-center justify-center border border-black">
            <Favorite width="32" height="32" className={cn(lineItemId ? "fill-orange-tangerine text-orange-tangerine" : "fill-white text-black")}/>
        </button>);
};
export const AddToCart = observer(({ className }) => {
    const { cartStore } = useStores();
    const { sku } = usePdp();
    const onAdd = usePdpAdded();
    const session = useSession();
    const { setPending } = usePending();
    const addProductToCart = async () => {
        if (!sku) {
            const $size = document.querySelector(`#sizeSelector`);
            if ($size) {
                $size.scrollIntoView({ behavior: "smooth", block: "center" });
            }
            return;
        }
        if (cartStore.isCtaDisabled)
            return;
        setPending(true);
        await cartStore.addProductToCart(session.status === "authenticated", sku).finally(() => setPending(false));
        onAdd();
    };
    const { $t } = useI18n();
    return (<div className={cn(className)}>
            <div className="grid grid-cols-[1fr,auto] gap-x-3">
                <button onClick={addProductToCart} className="h-12 w-full bg-black text-center text-sm uppercase text-gray-cream focus:outline-none md:w-auto">
                    {$t("Add To Cart")}
                </button>
                <Wishlist />
            </div>
        </div>);
});
