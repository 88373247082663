import { getGuidedSelling } from "@/apis/get-guided-selling";
import { GRichText, LogoLoader } from "@/components";
import { Arrow, CheckInGuidedSelling, NextImage, useGuidedSelling, useI18n } from "@jog/react-components";
import { asImageSrc, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
const SelectItem = ({ isSelected, answer, onClick }) => (<div onClick={(e) => {
        e.stopPropagation();
        onClick(answer);
    }} className={classNames("relative flex h-15 w-[300px] cursor-pointer items-center border-3 border-transparent px-6.25 text-xs", isSelected ? "bg-purple-light" : "bg-white")}>
        {answer.imageurl && (<NextImage src={answer.imageurl} alt="optionImg" className="mr-5 h-8.25 w-auto" width={33} height={33}/>)}
        {answer.answer}
        {isSelected && (<CheckInGuidedSelling className="absolute top-3.75 right-2.5 text-black" width={24} height={24}/>)}
    </div>);
export const GuidedSelling = ({ slice: { primary }, className }) => {
    const { loading, navigateLoading, step, steps, answersArr, currentQuestion, currentSelected, isSizeQuestion, currentSelectedText, dropdownShow, setDropdownShow, onClickMultiple, onClickSingle, nextStep, previousStep, } = useGuidedSelling(primary.code, getGuidedSelling);
    const { $t } = useI18n();
    if (!isFilled.image(primary.guide_image))
        return <></>;
    return (<div className={className}>
            {(loading || navigateLoading) && <LogoLoader />}
            {currentQuestion ? (<div className="bg-purple-dark" onClick={() => isSizeQuestion && dropdownShow && setDropdownShow(false)}>
                    <div className="pt-12 pb-20">
                        <div className="relative mx-5 h-0.5 bg-purple-lilac lg:mx-15">
                            <div className="absolute -top-0.75 flex w-full justify-between">
                                {Array.from({ length: steps }).map((_, index) => (<div key={index} className={classNames("h-2 w-2 rounded-full border-2 border-purple-lilac", index - 1 < step ? "bg-white" : "bg-purple-lilac")}/>))}
                            </div>
                        </div>
                        <h1 className="mx-auto my-16 w-[330px] text-center font-erotique text-3.5xl font-medium tracking-wide text-gray-cream lg:w-[450px]">
                            {currentQuestion.question}
                        </h1>
                        {currentQuestion.ismultiselect && (<div className="mx-auto -mt-12 mb-8 w-[330px] -rotate-[1.9deg] text-right font-rumpi text-xl text-gray-cream lg:w-[450px]">
                                {$t("Multiple options are available")}
                            </div>)}
                        {isSizeQuestion ? (<div className="mx-auto w-[320px] bg-white">
                                <div className="flex h-15 cursor-pointer select-none items-center justify-between px-6.25" onClick={() => setDropdownShow(!dropdownShow)}>
                                    <span className="overflow-x-hidden text-sm font-semibold">
                                        {currentSelectedText || $t("Select your size")}
                                    </span>
                                    {!dropdownShow && <Arrow size="middle" direction="down"/>}
                                </div>
                                <div className={classNames("max-h-[300px] overflow-y-auto overflow-x-hidden", dropdownShow ? "block" : "hidden")}>
                                    {currentQuestion.answers.map((answer) => (<SelectItem isSelected={currentSelected.includes(`${answer.answerid}`)} key={answer.answerid} answer={answer} onClick={currentQuestion.ismultiselect ? onClickMultiple : onClickSingle}/>))}
                                </div>
                            </div>) : (<div className="flex flex-col items-center gap-y-3">
                                {currentQuestion.answers.map((answer) => (<SelectItem isSelected={currentSelected.includes(`${answer.answerid}`)} key={answer.answerid} answer={answer} onClick={currentQuestion.ismultiselect ? onClickMultiple : onClickSingle}/>))}
                            </div>)}
                        <div className="flex justify-center">
                            <div className="mt-16 flex flex-col items-center justify-center gap-y-3">
                                {currentQuestion.skippable && (<button className="flex w-full min-w-[230px] items-center justify-center bg-purple-lilac py-3 px-16 text-xs font-medium leading-5" onClick={() => {
                    const newAnswersArr = [
                        ...answersArr,
                        ["skip_qs", currentQuestion.questionid],
                    ];
                    nextStep(newAnswersArr);
                }}>
                                        {currentQuestion.skiplabel}
                                    </button>)}
                                <button className="flex w-full min-w-[230px] items-center justify-center bg-purple-lilac py-3 px-16 text-xs font-medium leading-5" style={{
                color: step === steps - 1
                    ? primary.guide_cta_end_color
                    : primary.guide_cta_process_color,
            }} onClick={() => {
                if (!currentSelected.length)
                    return;
                const newAnswersArr = [
                    ...answersArr,
                    [`qs${currentQuestion.questionid}`, currentSelected.join(",")],
                ];
                nextStep(newAnswersArr);
            }}>
                                    {step === steps - 1 ? primary.guide_cta_end_text : primary.guide_cta_process_text}
                                </button>
                                <button className={classNames("flex w-full min-w-[230px] items-center justify-center border border-purple-lilac py-3 px-16 text-xs font-medium leading-5 text-gray-cream", step === 0 ? "hidden" : "block")} onClick={previousStep}>
                                    {$t("back")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>) : (<div className="relative">
                    <NextImage width={300} height={150} className="hidden min-h-[300px] w-full object-cover lg:block lg:min-h-0 lg:object-contain" src={asImageSrc(primary.guide_image)} alt={primary.guide_image.alt} quality={100} sizes="100vw"/>
                    <NextImage width={300} height={150} className="block min-h-[300px] w-full object-cover lg:hidden lg:min-h-0 lg:object-contain" src={asImageSrc(isFilled.image(primary.guide_image_for_mobile)
                ? primary.guide_image_for_mobile
                : primary.guide_image)} alt={isFilled.image(primary.guide_image_for_mobile)
                ? primary.guide_image_for_mobile.alt
                : primary.guide_image.alt} quality={100} sizes="100vw"/>
                    <div className="absolute top-1/2 left-3.5 -translate-y-1/2 text-purple-dark lg:left-[90px]" style={{ color: primary.guide_text_color }}>
                        <div className="mb-3 text-3.5xl font-medium leading-none lg:text-[40px] lg:leading-normal">
                            {primary.guide_text}
                        </div>
                        <div className="-mb-2">
                            <GRichText field={primary.guided_selling_description}/>
                        </div>
                        <button className="mt-3 w-[230px] bg-white py-3 text-xs font-medium leading-5 lg:mt-6" style={{
                color: primary.guide_cta_start_color,
                backgroundColor: primary.guide_cta_bg_color,
            }} onClick={() => nextStep([])}>
                            {primary.guide_cta_start_text}
                        </button>
                    </div>
                </div>)}
        </div>);
};
