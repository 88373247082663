import { AccordionContent, AccordionItem, AccordionRoot, AccordionTrigger, useI18n, usePdp, } from "@jog/react-components";
import { Skeleton } from "@jog/react-components";
import { Fragment } from "react";
export const Attributes = () => {
    const { detail } = usePdp();
    const { $t } = useI18n();
    if (!detail.mappedAttributes.length)
        return <Skeleton className="h-[230px]"/>;
    return (<div className="border-t border-black py-6 px-1" id="details">
            <AccordionRoot type="single" collapsible defaultValue="">
                <AccordionItem value="product-details">
                    <AccordionTrigger>{$t("Product details")}</AccordionTrigger>
                    <AccordionContent>
                        <div className="grid grid-cols-[auto,1fr] gap-x-6 text-sm text-black">
                            {detail.mappedAttributes.map((attribute) => (<Fragment key={attribute.name}>
                                    <span>{attribute.name}</span>
                                    <span>
                                        {attribute.capitalize
                ? attribute.value.replace(/^(.)/, (v) => v.toUpperCase())
                : attribute.value}
                                    </span>
                                </Fragment>))}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </div>);
};
