import { RichTextBase } from "../RichText/RichText";
const RichText = RichTextBase({ paragraphDefaultCss: "text-base" });
export const PromoInformationSlice = ({ color, richTexts }) => {
    if (!richTexts.length) {
        return <></>;
    }
    return (<div style={{ color }}>
            {richTexts.map((item, index) => (<RichText key={index} field={item} paragraphClass="font-primary text-base font-normal !mb-0" h3Class="text-lg font-medium leading-[30px]"/>))}
        </div>);
};
