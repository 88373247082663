import { LogoLoader, Sizes } from "@/components";
import { Ruler } from "@/components/Icon/Ruler";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { FasletWidget, PdpLoader, genPdpImageStyle, usePdp } from "@jog/react-components";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { AddToCart } from "./AddToCart";
import { Attributes } from "./Attributes";
import { Carousel } from "./Carousel";
import { CheckMarks } from "./CheckMarks";
import { Description } from "./Description";
import { GroupSelector } from "./GroupSelector";
import { Header } from "./Header";
import { Main } from "./Main";
import { SizeConflict } from "./SizeConflict";
import { SizeModalButton } from "./SizeModal";
import { Sustainable } from "./Sustainable";
export const Pdp = observer(() => {
    const { cartStore } = useStores();
    const wishlistLoading = useWishlist.use.actionPending();
    const { setSku, pending, productData, detail } = usePdp();
    const style = useMemo(() => genPdpImageStyle({ x: 12, y: 9, out: 0 }, { lg: 84, xl: 106, xxl: 128 }, detail.desktopImages.length), [detail]);
    return (<Main>
            {wishlistLoading && <LogoLoader />}
            <div style={style} className="grid grid-cols-1 gap-y-6 gap-x-[75px] py-5 px-3 lg:mb-3.75 lg:grid-cols-[var(--lg-carousel-w),1fr] lg:p-0 xl:grid-cols-[var(--xl-carousel-w),1fr] 2xl:grid-cols-[var(--xxl-carousel-w),1fr]">
                <div className="col-span-1">
                    <Carousel />
                </div>
                <div className="col-span-1 grid h-fit gap-y-6 lg:gap-y-7">
                    <Header />
                    {pending ? (<PdpLoader />) : (<>
                            <GroupSelector />
                            <Sizes sizes={productData.sizeGroup} setSku={setSku}/>
                            {detail.isFasletShow ? (<FasletWidget key={detail.ctpId} cartStore={cartStore} Icon={<Ruler width={20} height={20}/>}/>) : detail.sizeImage ? (<SizeModalButton />) : null}
                            <SizeConflict />
                        </>)}

                    <AddToCart className="hidden lg:block"/>
                    {/* CONTAINER - Persuasion Social Proof from sqzly */}
                    <div className="sqzly-proof-contain hidden"/>
                    <div>
                        <CheckMarks />
                        <Attributes />
                        <Description />
                        <Sustainable />
                    </div>
                </div>
            </div>
            <AddToCart className="font-avenirNext sticky bottom-0 z-[9] bg-white py-6 px-3 shadow-add-to-cart lg:hidden"/>

            {/* CONTAINER - Recommended product from sqzly */}
            <div className="sqzly-recommended-product"/>
            {/* CONTAINER - product history from sqzly */}
            <div className="sqzly-product-history"/>
        </Main>);
});
