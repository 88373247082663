import { Head } from "@/components";
import PageNotFoundContent from "@/containers/PageNotFoundContent/PageNotFoundContent";
import Layout from "@/layout";
import { prefetchPageStaticProps } from "@/lib";
import { useNotFound } from "@/lib/prismicio/useNotFound";
import { getRevalidateTime } from "@jog/shared";
export const getNotFoundProps = async (pageContext) => {
    const { loader } = await prefetchPageStaticProps(pageContext);
    return {
        props: {
            loader,
            page: {
                type: "404",
                content: null,
            },
        },
        revalidate: getRevalidateTime(),
    };
};
export const PageNotFound = () => {
    const data = useNotFound();
    if (!data) {
        console.error("404 no prismic content");
        return <Layout />;
    }
    return (<Layout>
            <Head title={data.meta.title} description={data.meta.description} robots={data.meta.robots}/>
            <PageNotFoundContent />
        </Layout>);
};
